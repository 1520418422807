// Adobe Fonts
@import url("https://use.typekit.net/fck3kkr.css");
// font-family: brandon-grotesque, sans-serif;
// font-weight: 900;
// font-style: normal;
//
// font-family: basic-sans,sans-serif;
// font-weight: 200;
// font-weight: 300;
// font-weight: 400;
// font-weight: 700;
// font-weight: 900;
// font-style: normal;

$family-sans-serif: "basic-sans", Helvetica, Arial, sans-serif;