$white: #fff;
$black: #000;

// Backgrounds
$blue-deep: #007d98;
$blue-light: #80cfd5;
$white-linen: #FBF6EA;

// Text
$text-blue: #007D98;
$text-yellow: #FFD573;
$text-off-white: #cfe5e7;
$text-white: #fff;

// Press
$press-bar-inactive: rgba(0, 125, 152, 0.3);
$press-bar-active: rgba(0, 125, 152, 1.0);