// Breakpoints

// 1215 - everything starts shrinking respectively
//        avoid how the video composition starts getting clipped
// 1440 - reference size
// 2560 px upper limit -- add white bars

//                       single-sided pixel measurements
//                      (logo/header)   (center)     (leftover)
//       min    max            red       grey     center
//      ----   -----          -----     ------   --------
//        -      575            
//  sm   576     789
//  md   790    1023           
//  lg  1024    1214           57px      117px     
//  lg  1215    1407           
//  xl  1408    1440          117px      117px
//  xl  1441    1920          117px      117px
//  xxl 1921    2560          384px      384px
//      2561     ∞            maxed out << add the white bars!

// xs                   // 575.98px
$screen-sm-min: 576px; 
// $screen-sm-max: 789px;  // 788.98px << Sproutel
$screen-sm-max: 768px;  // 767.98px << Bootstrap
// $screen-md-min: 790px; // << Sproutel
$screen-md-min: 769px; // << Bootstrap
$screen-md-max: 1023px;  // 1022.98px
$screen-lg-min: 1024px;
$screen-lg-max: 1407px;  // 1406.98px
$screen-xl-min: 1408px;
$screen-xl-max: 1920px;  // 1919.98px
$screen-xxl-min: 1921px; //
$screen-xxl-max: 2560px; //

$xs-padding-header: 57px;
$xs-padding-body: 0px;
$sm-padding-header: 57px;
$sm-padding-body: 0px;
$md-padding-header: 57px;
$md-padding-body: 57px;
$lg-padding-header: 57px;
$lg-padding-body: 117px;
$xl-padding-header: 117px;
$xl-padding-body: 117px;
$xxl-padding-header: 384px;
$xxl-padding-body: 384px;

// Z-indices
$z-index-header: 100;
$z-index-video-title: 10;

*, *::before, *::after {
  /* FIXME From Bootstrap */
  box-sizing: inherit;
  /* FIXME From Bootstrap-reboot */
  box-sizing: border-box;
}

html {
  /* FIXME From Bootstrap */
  box-sizing: border-box;
  -ms-overflow-style: scrollbar;
  background-color: var(--background-grey);
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  /* FIXME */
  overflow-x: hidden;
}

// Prevent scrolling when menu is open
html.stop-scrolling {
  overflow: hidden;
}

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block;
}

body {
  font-family: $family-sans-serif;
  font-weight: 200;
  font-size: 22px; /* TODO font-size in rem? */
  line-height: 22px;
  color: var(--text-grey);
  text-align: left;
  /* Max width for screens */
  margin: auto;
  max-width: $screen-xxl-max;
}

header {
  /* Max width for screens */
  margin: auto;
  max-width: 2560px; // Bigger than $screen-xxl-max
}

[tabindex="-1"]:focus:not(:focus-visible) {
  outline: 0 !important;
}

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 1rem;
  color: $text-blue;
  font-family: "brandon-grotesque"; 
}

h1 {
  font-size: 65px; //FIXME rem?
  line-height: 65px; //FIXME rem?
  font-weight: 600;
  margin-bottom: 0.5em;
  @media (max-width: $screen-sm-max) {
    font-size: 45px;
    line-height: 50px;
  }
}

h1.subtitle {
  font-family: $family-sans-serif;
  font-size: 60px; //FIXME rem?
  line-height: 70px; //FIXME rem?
  font-weight: 300;
  @media (max-width: $screen-sm-max) {
    font-size: 40px;
    line-height: 50px;
  }
}

h2 {
  font-size: 40px; //FIXME rem?
  line-height: 48px; //FIXME rem?
  font-weight: 600;
  @media (max-width: $screen-sm-max) {
    font-size: 28px; //FIXME rem?
    line-height: 34px; //FIXME rem?
  }
}

p {
  font-size: 28px; //FIXME rem?
  line-height: 32px; //FIXME rem?
  margin-top: 0;
  margin-bottom: 0;
  color: $text-blue;
  @media (max-width: $screen-sm-max) {
    font-size: 24px; //FIXME rem?
    line-height: 32px; //FIXME rem?
  }
}

.color-white {
  color: $text-white;
}

// @media (max-width: 779px) {
//   h1 {
//     font-size: 35px; //FIXME rem?
//     line-height: 35px; //FIXME rem?
//   }
//   p, h2 {
//     font-size: 20px; //FIXME rem?
//     line-height: 20px; //FIXME rem?
//   }
// }

a {
  text-decoration: none;
  background-color: transparent;
  
  &:hover {
    text-decoration: underline;
  }

  &.button {
    display: inline;
    background-color: $blue-deep;
    width: 250px;
    height: 50px;
    // Applying a very large radius makes a pill shape
    // https://drafts.csswg.org/css-backgrounds/#corner-overlap
    border-radius: 500px;
    border: 4px solid transparent;
    background-clip: padding-box;
    padding-left: 70px;
    padding-right: 70px;
    padding-top: 14px;
    padding-bottom: 14px;
    white-space: nowrap;
    font-family: $family-sans-serif;
    font-weight: 900;
    color: $text-yellow;
    text-transform: uppercase;

    text-shadow: 0.5px 0.5px darkblue; //FIXME
  }

  &.button:hover {
    text-decoration: none;
    border: 4px solid $blue-light;
  }
}

button.order {
  display: inline;
    background-color: $blue-deep;
    // Applying a very large radius makes a pill shape
    // https://drafts.csswg.org/css-backgrounds/#corner-overlap
    border-radius: 500px;
    border: 4px solid transparent;
    background-clip: padding-box;
    padding-left: 70px;
    padding-right: 70px;
    padding-top: 14px;
    padding-bottom: 14px;
    white-space: nowrap;
    font-family: $family-sans-serif;
    font-weight: 900;
    color: $text-yellow;
    text-transform: uppercase;

    text-shadow: 0.5px 0.5px darkblue;

    p {
      color: $text-yellow;
    }

  &:hover {
    text-decoration: none;
    border: 4px solid $blue-light;
  }
}

input, button, select, optgroup, textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button, input {
  overflow: visible;
}

button, select {
  text-transform: none;
}

button:focus:not(:focus-visible) {
  outline: 0;
}

/* Screen reader */

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}

/* Container, Row, and Column grid system from Bootstrap 4.6 */

/* https://getbootstrap.com/docs/4.6/layout/grid/ */

.container {
  width: 100%;
  padding-right: $xxl-padding-body;
  padding-left: $xxl-padding-body;
  margin-right: auto;
  margin-left: auto;

  @media (max-width: $screen-xl-max) {
    padding-right: $xl-padding-body;
    padding-left: $xl-padding-body;
  }
  @media (max-width: $screen-lg-max) {
    padding-right: $lg-padding-body;
    padding-left: $lg-padding-body;
  }
  @media (max-width: $screen-md-max) {
    padding-right: $md-padding-body;
    padding-left: $md-padding-body;
  }
  @media (max-width: $screen-sm-max) {
    padding-right: $sm-padding-body;
    padding-left: $sm-padding-body;
  }
}

header .container {
  padding-right: $xxl-padding-header;
  padding-left: $xxl-padding-header;

  @media (max-width: $screen-xl-max) {
    padding-right: $xl-padding-header;
    padding-left: $xl-padding-header;
  }
  @media (max-width: $screen-lg-max) {
    padding-right: $lg-padding-header;
    padding-left: $lg-padding-header;
  }
  @media (max-width: $screen-md-max) {
    padding-right: $md-padding-header;
    padding-left: $md-padding-header;
  }
  @media (max-width: $screen-sm-max) {
    padding-right: $sm-padding-header;
    padding-left: $sm-padding-header;
  }
}

footer .container {
  padding-right: $xxl-padding-header;
  padding-left: $xxl-padding-header;

  @media (max-width: $screen-xl-max) {
    padding-right: $xl-padding-header;
    padding-left: $xl-padding-header;
  }
  @media (max-width: $screen-lg-max) {
    padding-right: $lg-padding-header;
    padding-left: $lg-padding-header;
  }
  @media (max-width: $screen-md-max) {
    padding-right: $md-padding-header;
    padding-left: $md-padding-header;
  }
  @media (max-width: $screen-sm-max) {
    padding-right: $sm-padding-header;
    padding-left: $sm-padding-header;
  }
}

.row {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: 0;
  margin-left: 0;
  /* margin-right: -15px;
  margin-left: -15px; */

  &.reverse-columns {
    flex-direction: row-reverse;
  }
}

.col, .col-33, .col-50, .col-auto,
.col-sm-33, .col-sm-50, .col-sm, .col-sm-auto,
.col-md-33, .col-md-50, .col-md, .col-md-auto,
.col-lg-33, .col-lg-50, .col-lg, .col-lg-auto {
  position: relative;
  width: 100%;
  padding-left: 15px;
  padding-right: 15px;
}

.col {
  -ms-flex-preferred-size: 0;
  flex-basis: 0;
  -ms-flex-positive: 1;
  flex-grow: 1;
  max-width: 100%;
}

.col-auto {
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  width: auto;
  max-width: 100%;
}

.col-33 {
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
  max-width: 50%;
}

.col-50 {
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
  max-width: 50%;
}

@media (min-width: $screen-sm-min) {
  .col-sm {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-sm-auto {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-sm-33 {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .col-sm-50 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
}

@media (min-width: $screen-md-min) {
  .col-md {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-md-auto {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-md-33 {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .col-md-50 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
}

@media (min-width: $screen-lg-min) {
  .col-lg {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-lg-auto {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-lg-33 {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .col-lg-50 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
}

.align-items-center {
  -ms-flex-align: center !important;
  align-items: center !important;
}

.align-self-center {
  -ms-flex-item-align: center !important;
  align-self: center !important;
}

/* Vertical */
.is-v-middle {
  margin-top: auto;
  margin-bottom: auto;
}

/* Text */
.text-is-left {
  text-align: left;
}

.text-is-center {
  text-align: center;
}

.text-is-right {
  text-align: right;
}

@media (min-width: 780px) {
  .text-is-left-md {
    text-align: left;
  }
  .text-is-center-md {
    text-align: center;
  }
  .text-is-right-md {
    text-align: right;
  }
}

@media (max-width: $screen-sm-max) {
  .text-is-left-xs {
    text-align: left;
  }
  .text-is-center-xs {
    text-align: center;
  }
  .text-is-right-xs {
    text-align: right;
  }
}

// FIXME subtract 0.2px for range error?
// Via Bootstrap

// Hidden on all
.d-none {
  display: none;
}

// Visible only on xs
.d-xs {
  @media (min-width: $screen-sm-min) {
    display: none;
  }
}

// Visible only on xs and sm
.d-xs-sm {
  @media (min-width: $screen-md-min) {
    display: none;
  }
}

// Visible on md and above
.d-md {
  @media (max-width: $screen-sm-max) {
    display: none;
  }
}