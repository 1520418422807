@import url("https://use.typekit.net/fck3kkr.css");
*, *::before, *::after {
  /* FIXME From Bootstrap */
  box-sizing: inherit;
  /* FIXME From Bootstrap-reboot */
  box-sizing: border-box;
}

html {
  /* FIXME From Bootstrap */
  box-sizing: border-box;
  -ms-overflow-style: scrollbar;
  background-color: var(--background-grey);
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  /* FIXME */
  overflow-x: hidden;
}

html.stop-scrolling {
  overflow: hidden;
}

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block;
}

body {
  font-family: "basic-sans", Helvetica, Arial, sans-serif;
  font-weight: 200;
  font-size: 22px;
  /* TODO font-size in rem? */
  line-height: 22px;
  color: var(--text-grey);
  text-align: left;
  /* Max width for screens */
  margin: auto;
  max-width: 2560px;
}

header {
  /* Max width for screens */
  margin: auto;
  max-width: 2560px;
}

[tabindex="-1"]:focus:not(:focus-visible) {
  outline: 0 !important;
}

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 1rem;
  color: #007D98;
  font-family: "brandon-grotesque";
}

h1 {
  font-size: 65px;
  line-height: 65px;
  font-weight: 600;
  margin-bottom: 0.5em;
}

@media (max-width: 768px) {
  h1 {
    font-size: 45px;
    line-height: 50px;
  }
}

h1.subtitle {
  font-family: "basic-sans", Helvetica, Arial, sans-serif;
  font-size: 60px;
  line-height: 70px;
  font-weight: 300;
}

@media (max-width: 768px) {
  h1.subtitle {
    font-size: 40px;
    line-height: 50px;
  }
}

h2 {
  font-size: 40px;
  line-height: 48px;
  font-weight: 600;
}

@media (max-width: 768px) {
  h2 {
    font-size: 28px;
    line-height: 34px;
  }
}

p {
  font-size: 28px;
  line-height: 32px;
  margin-top: 0;
  margin-bottom: 0;
  color: #007D98;
}

@media (max-width: 768px) {
  p {
    font-size: 24px;
    line-height: 32px;
  }
}

.color-white {
  color: #fff;
}

a {
  text-decoration: none;
  background-color: transparent;
}

a:hover {
  text-decoration: underline;
}

a.button {
  display: inline;
  background-color: #007d98;
  width: 250px;
  height: 50px;
  border-radius: 500px;
  border: 4px solid transparent;
  background-clip: padding-box;
  padding-left: 70px;
  padding-right: 70px;
  padding-top: 14px;
  padding-bottom: 14px;
  white-space: nowrap;
  font-family: "basic-sans", Helvetica, Arial, sans-serif;
  font-weight: 900;
  color: #FFD573;
  text-transform: uppercase;
  text-shadow: 0.5px 0.5px darkblue;
}

a.button:hover {
  text-decoration: none;
  border: 4px solid #80cfd5;
}

button.order {
  display: inline;
  background-color: #007d98;
  border-radius: 500px;
  border: 4px solid transparent;
  background-clip: padding-box;
  padding-left: 70px;
  padding-right: 70px;
  padding-top: 14px;
  padding-bottom: 14px;
  white-space: nowrap;
  font-family: "basic-sans", Helvetica, Arial, sans-serif;
  font-weight: 900;
  color: #FFD573;
  text-transform: uppercase;
  text-shadow: 0.5px 0.5px darkblue;
}

button.order p {
  color: #FFD573;
}

button.order:hover {
  text-decoration: none;
  border: 4px solid #80cfd5;
}

input, button, select, optgroup, textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button, input {
  overflow: visible;
}

button, select {
  text-transform: none;
}

button:focus:not(:focus-visible) {
  outline: 0;
}

/* Screen reader */
.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}

/* Container, Row, and Column grid system from Bootstrap 4.6 */
/* https://getbootstrap.com/docs/4.6/layout/grid/ */
.container {
  width: 100%;
  padding-right: 384px;
  padding-left: 384px;
  margin-right: auto;
  margin-left: auto;
}

@media (max-width: 1920px) {
  .container {
    padding-right: 117px;
    padding-left: 117px;
  }
}

@media (max-width: 1407px) {
  .container {
    padding-right: 117px;
    padding-left: 117px;
  }
}

@media (max-width: 1023px) {
  .container {
    padding-right: 57px;
    padding-left: 57px;
  }
}

@media (max-width: 768px) {
  .container {
    padding-right: 0px;
    padding-left: 0px;
  }
}

header .container {
  padding-right: 384px;
  padding-left: 384px;
}

@media (max-width: 1920px) {
  header .container {
    padding-right: 117px;
    padding-left: 117px;
  }
}

@media (max-width: 1407px) {
  header .container {
    padding-right: 57px;
    padding-left: 57px;
  }
}

@media (max-width: 1023px) {
  header .container {
    padding-right: 57px;
    padding-left: 57px;
  }
}

@media (max-width: 768px) {
  header .container {
    padding-right: 57px;
    padding-left: 57px;
  }
}

footer .container {
  padding-right: 384px;
  padding-left: 384px;
}

@media (max-width: 1920px) {
  footer .container {
    padding-right: 117px;
    padding-left: 117px;
  }
}

@media (max-width: 1407px) {
  footer .container {
    padding-right: 57px;
    padding-left: 57px;
  }
}

@media (max-width: 1023px) {
  footer .container {
    padding-right: 57px;
    padding-left: 57px;
  }
}

@media (max-width: 768px) {
  footer .container {
    padding-right: 57px;
    padding-left: 57px;
  }
}

.row {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: 0;
  margin-left: 0;
  /* margin-right: -15px;
  margin-left: -15px; */
}

.row.reverse-columns {
  flex-direction: row-reverse;
}

.col, .col-33, .col-50, .col-auto,
.col-sm-33, .col-sm-50, .col-sm, .col-sm-auto,
.col-md-33, .col-md-50, .col-md, .col-md-auto,
.col-lg-33, .col-lg-50, .col-lg, .col-lg-auto {
  position: relative;
  width: 100%;
  padding-left: 15px;
  padding-right: 15px;
}

.col {
  -ms-flex-preferred-size: 0;
  flex-basis: 0;
  -ms-flex-positive: 1;
  flex-grow: 1;
  max-width: 100%;
}

.col-auto {
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  width: auto;
  max-width: 100%;
}

.col-33 {
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
  max-width: 50%;
}

.col-50 {
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
  max-width: 50%;
}

@media (min-width: 576px) {
  .col-sm {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-sm-auto {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-sm-33 {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .col-sm-50 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
}

@media (min-width: 769px) {
  .col-md {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-md-auto {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-md-33 {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .col-md-50 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
}

@media (min-width: 1024px) {
  .col-lg {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-lg-auto {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-lg-33 {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .col-lg-50 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
}

.align-items-center {
  -ms-flex-align: center !important;
  align-items: center !important;
}

.align-self-center {
  -ms-flex-item-align: center !important;
  align-self: center !important;
}

/* Vertical */
.is-v-middle {
  margin-top: auto;
  margin-bottom: auto;
}

/* Text */
.text-is-left {
  text-align: left;
}

.text-is-center {
  text-align: center;
}

.text-is-right {
  text-align: right;
}

@media (min-width: 780px) {
  .text-is-left-md {
    text-align: left;
  }
  .text-is-center-md {
    text-align: center;
  }
  .text-is-right-md {
    text-align: right;
  }
}

@media (max-width: 768px) {
  .text-is-left-xs {
    text-align: left;
  }
  .text-is-center-xs {
    text-align: center;
  }
  .text-is-right-xs {
    text-align: right;
  }
}

.d-none {
  display: none;
}

@media (min-width: 576px) {
  .d-xs {
    display: none;
  }
}

@media (min-width: 769px) {
  .d-xs-sm {
    display: none;
  }
}

@media (max-width: 768px) {
  .d-md {
    display: none;
  }
}

header {
  position: fixed;
  left: 0;
  right: 0;
  z-index: 100;
}

header div.holiday-deadline {
  background-color: #007d98;
  width: 100%;
}

header div.holiday-deadline p.message {
  color: #fff;
  font-size: 20px;
  font-family: "brandon-grotesque";
  font-weight: 700;
  margin: auto;
  padding-top: 4px;
  padding-bottom: 4px;
}

header div.holiday-deadline p.message.is-hidden-mobile {
  display: inline-block;
}

@media (max-width: 576px) {
  header div.holiday-deadline p.message.is-hidden-mobile {
    display: none;
  }
}

header div.holiday-deadline p.message.is-visible-mobile {
  display: none;
}

@media (max-width: 576px) {
  header div.holiday-deadline p.message.is-visible-mobile {
    display: inline-block;
  }
}

header div.discount {
  background-color: #FFD573;
  width: 100%;
  text-align: center;
  padding-top: 10px;
  padding-bottom: 10px;
}

header div.discount a.message {
  color: #007D98;
  font-size: 20px;
  font-family: "brandon-grotesque";
  font-weight: 700;
  margin: auto;
  padding-top: 4px;
  padding-bottom: 4px;
  text-decoration: none;
}

header div.discount a.message.is-hidden-mobile {
  display: inline-block;
}

@media (max-width: 576px) {
  header div.discount a.message.is-hidden-mobile {
    display: none;
  }
}

header div.discount a.message.is-visible-mobile {
  display: none;
}

@media (max-width: 576px) {
  header div.discount a.message.is-visible-mobile {
    display: inline-block;
  }
}

header div.discount br.is-visible-mobile {
  display: none;
}

@media (max-width: 576px) {
  header div.discount br.is-visible-mobile {
    display: inline-block;
  }
}

header nav {
  background-color: white;
  transition: all 300ms ease-in-out;
}

header nav a {
  color: #007d98;
}

header nav svg {
  fill: #007d98;
}

header nav.is-transparent {
  background-color: rgba(255, 255, 255, 0);
}

header nav.is-transparent a {
  color: #fff;
}

header nav.is-transparent svg {
  fill: #fff;
}

header nav.no-transparency {
  background-color: white;
}

header nav.no-transparency a {
  color: #007d98;
}

header nav.no-transparency svg {
  fill: #007d98;
}

header .container {
  padding-top: 40px;
  padding-bottom: 10px;
}

header .row {
  margin: 0;
}

header .header-logo .logo {
  display: block;
  width: 180px;
}

header a.mobile-cart {
  display: none;
}

header .menu {
  margin-left: auto;
  margin-right: 0;
}

header .menu div.main-menu ul {
  display: block;
  list-style-type: none;
  float: left;
  font-size: 14px;
  line-height: 24px;
}

header .menu div.main-menu ul li {
  display: list-item;
  float: left;
  font-size: 1rem;
}

header .menu div.main-menu ul li a {
  font-family: "brandon-grotesque";
  text-transform: uppercase;
  text-underline-offset: 4px;
}

header .menu div.main-menu ul li a:hover {
  color: #FFD573;
}

header .menu div.main-menu ul li a:hover svg {
  fill: #FFD573;
}

header .menu div.main-menu ul li.text-item {
  padding: 14px 15px 14px 15px;
}

header .menu div.main-menu ul li.text-item:nth-child(1) {
  padding-left: 0;
}

header .menu div.main-menu ul li.image-item {
  padding: 0 0 0 15px;
}

header .menu div.main-menu ul li.image-item span {
  display: none;
  visibility: hidden;
}

header .dim-the-lights {
  display: none;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: -1;
  position: fixed;
  top: 0;
}

@media (max-width: 768px) {
  header .container {
    padding-top: 12px;
    padding-left: 0;
    padding-right: 0;
    text-align: center;
  }
  header a.mobile-cart {
    display: block;
    position: absolute;
    right: 0;
  }
  header a.mobile-cart svg {
    position: relative;
    top: 6px;
    right: 15px;
  }
  .header-logo {
    margin: auto;
  }
  .header-logo .logo {
    width: 180px;
  }
  .main-menu {
    display: none;
    width: 100%;
  }
  a.menu-open {
    position: absolute;
    left: 0;
  }
  img.main-menu-toggle {
    width: 50px;
    height: 55px;
    position: relative;
    top: 0px;
    left: 0px;
  }
  #main-menu:target nav, #main-menu[aria-expanded="true"] nav {
    background-color: #80cfd5;
  }
  #main-menu:target nav svg, #main-menu[aria-expanded="true"] nav svg {
    fill: #fff;
  }
  #main-menu:target .main-menu, #main-menu[aria-expanded="true"] .main-menu {
    display: block;
  }
  #main-menu:target .dim-the-lights, #main-menu[aria-expanded="true"] .dim-the-lights {
    display: block;
  }
  #main-menu:target .menu-close, #main-menu[aria-expanded="true"] .menu-close {
    z-index: 1001;
    display: none;
  }
  #main-menu:target .mobile-cart, #main-menu[aria-expanded="true"] .mobile-cart {
    display: none;
  }
  #main-menu:target .close-menu, #main-menu[aria-expanded="true"] .close-menu {
    z-index: 1001;
    display: block;
    width: 61px;
    height: 61px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 32px;
    margin-bottom: 165px;
  }
  #main-menu:target ul, #main-menu[aria-expanded="true"] ul {
    z-index: 1000;
    float: none;
    padding-inline-start: 0;
  }
  #main-menu:target ul li, #main-menu[aria-expanded="true"] ul li {
    float: none;
    padding: 32px 0 32px 0;
  }
  #main-menu:target ul li a, #main-menu[aria-expanded="true"] ul li a {
    color: #007d98;
    font-family: "basic-sans", Helvetica, Arial, sans-serif;
    font-size: 32px;
    font-weight: 700;
    text-transform: none;
  }
  #main-menu:target ul li a svg, #main-menu[aria-expanded="true"] ul li a svg {
    display: none;
  }
  #main-menu:target ul li a span, #main-menu[aria-expanded="true"] ul li a span {
    display: block;
    visibility: visible;
  }
  #main-menu:target ul li:nth-child(1), #main-menu[aria-expanded="true"] ul li:nth-child(1) {
    padding-top: 80px;
  }
  a.menu-open {
    display: block;
  }
  #main-menu:target *.menu-open, #main-menu[aria-expanded="true"] *.menu-open {
    display: none;
  }
  a.menu-close {
    display: none;
  }
  #main-menu:target a.menu-close, #main-menu[aria-expanded="true"] a.menu-close {
    display: block;
  }
  .menu {
    position: relative;
    width: 100%;
  }
}

footer {
  padding-bottom: 2rem;
  background-color: #007d98;
  position: relative;
}

footer div.calm-wave {
  background-image: url(/images/footer-wave.svg);
  background-repeat: repeat-x;
  user-select: none;
  white-space: nowrap;
  position: absolute;
  top: -32px;
  width: 100%;
  height: 50px;
  animation: wave 120s linear infinite;
  transform: translate3d(0, 0, 0);
}

@media (prefers-reduced-motion) {
  footer div.calm-wave {
    animation: none;
  }
}

footer div.container {
  padding-top: 5rem;
}

footer .footer-company {
  float: left;
}

@media (max-width: 768px) {
  footer .footer-company {
    margin-left: auto;
    margin-right: auto;
    width: 100%;
  }
  footer .footer-company ul {
    margin-left: auto;
    margin-right: auto;
    width: 100%;
  }
}

footer .footer-company ul {
  padding-inline-start: 0;
}

footer .footer-company ul li svg {
  width: 150px;
}

footer .footer-links {
  margin-left: auto;
  margin-right: 0;
  padding-left: 0;
  padding-right: 0;
}

@media (max-width: 768px) {
  footer .footer-links {
    margin-left: auto;
    margin-right: auto;
    padding-top: 24px;
  }
  footer .footer-links ul {
    padding-inline-start: 0;
    padding-left: 15px;
    padding-right: 15px;
  }
}

footer img.logo {
  max-width: 150px;
}

footer ul {
  display: block;
  list-style-type: none;
  float: left;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0;
}

footer ul li {
  display: list-item;
  font-size: 1rem;
}

footer ul li strong {
  color: white;
  font-weight: 400;
}

footer ul li a {
  color: #cfe5e7;
}

footer ul li a:hover {
  color: white;
  text-decoration: underline;
  transition: color linear 80ms;
}

footer .made-with-love {
  margin-top: 4rem;
  color: #cfe5e7;
  font-size: 16px;
}

footer .made-with-love img {
  width: 4rem;
  height: auto;
  margin-bottom: -0.25rem;
  margin-left: -0.25rem;
}

footer .copyright {
  width: 90%;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
}

footer .copyright p {
  color: #cfe5e7;
  font-size: 12.8px;
  line-height: normal;
  vertical-align: middle;
}

footer .copyright p span.symbol {
  font-size: 8px;
  vertical-align: middle;
}

@keyframes wave {
  0% {
    background-position-x: 0px;
  }
  100% {
    background-position-x: 6400px;
  }
}

section {
  padding-top: 4rem;
  padding-bottom: 4rem;
}

section div.click-block {
  min-height: 100%;
  min-width: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
}

section.video {
  background: #80cfd5;
  padding: 0;
  height: 640px;
  position: relative;
}

@media (max-width: 576px) {
  section.video {
    top: 64px;
  }
}

section.video div.wistia_embed {
  height: 642px;
}

section.video div.video-title {
  margin: 0;
  height: 100px;
  position: absolute;
  left: 0;
  right: 0;
  top: 340px;
  z-index: 10;
}

@media (min-width: 1408px) {
  section.video div.video-title {
    top: 420px;
  }
}

section.video div.video-title h1 {
  color: #007d98;
  text-shadow: 2.5px 2.5px #B2D0D7;
  padding-bottom: 24px;
}

@media (min-width: 1408px) {
  section.video div.video-title h1 {
    padding-bottom: 24px;
  }
}

section.video .w-video-wrapper video {
  object-position: center 25% !important;
}

section.press {
  background: #FBF6EA;
  padding-top: 80px;
  padding-bottom: 80px;
}

section.press .container {
  padding-left: 0;
  padding-right: 0;
}

section.press input.radio-button {
  display: none;
}

section.press .press-logos {
  position: relative;
}

section.press .press-logos .nav-arrow {
  width: 20px;
  position: absolute;
  top: 22px;
}

section.press .press-logos .nav-arrow.left {
  left: 15px;
}

section.press .press-logos .nav-arrow.right {
  right: 15px;
}

section.press .logos {
  display: block;
  margin-left: auto;
  margin-right: auto;
  padding-left: 0;
  padding-right: 0;
}

section.press ul {
  display: block;
  list-style-type: none;
  float: left;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0;
  max-width: 100%;
  padding-inline-start: 0;
}

section.press ul li {
  display: list-item;
  font-size: 1rem;
  float: left;
  border-bottom: 2px solid rgba(0, 125, 152, 0.3);
}

section.press ul li label {
  cursor: pointer;
}

section.press ul li svg {
  display: block;
  height: 80px;
  max-height: 80px;
  fill: rgba(0, 125, 152, 0.3);
  transition: fill 300ms ease-in-out;
  padding-left: 25px;
  padding-right: 25px;
  padding-top: 8px;
  padding-bottom: 24px;
}

@media (min-width: 1921px) {
  section.press ul li svg {
    height: calc((100vw - 768px - 250px) / 15);
    min-height: 70px;
  }
}

@media (max-width: 1920px) {
  section.press ul li svg {
    height: calc((100vw - 234px - 250px) / 15);
    min-height: 70px;
  }
}

@media (max-width: 1407px) {
  section.press ul li svg {
    height: calc(70vw / 12);
    min-height: 67.9219px;
  }
}

@media (max-width: 1023px) {
  section.press ul li svg {
    height: calc(80vw / 12);
    min-height: 58px;
  }
}

@media (max-width: 768px) {
  section.press ul li svg {
    min-height: 72px;
  }
}

@media (max-width: 1407px) {
  section.press ul li svg {
    padding-left: 1vw;
    padding-right: 1vw;
  }
}

@media (max-width: 1023px) {
  section.press ul li svg {
    padding-left: 1vw;
    padding-right: 1vw;
  }
}

section.press .pull-quote {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

section.press .pull-quote a {
  display: none;
  text-decoration: none;
  padding-top: 60px;
}

@media (min-width: 769px) {
  section.press #p1:checked ~ .row * li.p1, section.press #p1:hover ~ .row * li.p1,
  section.press #p2:checked ~ .row * li.p2, section.press #p2:hover ~ .row * li.p2,
  section.press #p3:checked ~ .row * li.p3, section.press #p3:hover ~ .row * li.p3,
  section.press #p4:checked ~ .row * li.p4, section.press #p4:hover ~ .row * li.p4,
  section.press #p5:checked ~ .row * li.p5, section.press #p5:hover ~ .row * li.p5 {
    border-bottom: 8px solid rgba(0, 125, 152, 0.3);
  }
  section.press #p1:checked ~ .row * li.p1 svg, section.press #p1:hover ~ .row * li.p1 svg,
  section.press #p2:checked ~ .row * li.p2 svg, section.press #p2:hover ~ .row * li.p2 svg,
  section.press #p3:checked ~ .row * li.p3 svg, section.press #p3:hover ~ .row * li.p3 svg,
  section.press #p4:checked ~ .row * li.p4 svg, section.press #p4:hover ~ .row * li.p4 svg,
  section.press #p5:checked ~ .row * li.p5 svg, section.press #p5:hover ~ .row * li.p5 svg {
    fill: #007d98;
  }
  section.press #p1:checked ~ .quotes .pull-quote a.p1,
  section.press #p2:checked ~ .quotes .pull-quote a.p2,
  section.press #p3:checked ~ .quotes .pull-quote a.p3,
  section.press #p4:checked ~ .quotes .pull-quote a.p4,
  section.press #p5:checked ~ .quotes .pull-quote a.p5 {
    display: block;
  }
  section.press #p1:checked ~ .quotes .pull-quote a,
  section.press #p2:checked ~ .quotes .pull-quote a,
  section.press #p3:checked ~ .quotes .pull-quote a,
  section.press #p4:checked ~ .quotes .pull-quote a,
  section.press #p5:checked ~ .quotes .pull-quote a {
    display: none;
  }
  section.press #p1:hover ~ .quotes .pull-quote a,
  section.press #p2:hover ~ .quotes .pull-quote a,
  section.press #p3:hover ~ .quotes .pull-quote a,
  section.press #p4:hover ~ .quotes .pull-quote a,
  section.press #p5:hover ~ .quotes .pull-quote a {
    display: none !important;
  }
  section.press #p1:hover ~ .quotes .pull-quote a.p1,
  section.press #p2:hover ~ .quotes .pull-quote a.p2,
  section.press #p3:hover ~ .quotes .pull-quote a.p3,
  section.press #p4:hover ~ .quotes .pull-quote a.p4,
  section.press #p5:hover ~ .quotes .pull-quote a.p5 {
    display: block !important;
  }
}

@media (max-width: 768px) {
  section.press .row * li {
    border-bottom: 8px solid rgba(0, 125, 152, 0.3);
  }
  section.press .row * li svg {
    fill: #007d98;
  }
  section.press .nav-arrow img {
    display: none;
    cursor: pointer;
  }
  section.press #m-p1:checked ~ .row > .nav-arrow img.m-p1,
  section.press #m-p2:checked ~ .row > .nav-arrow img.m-p2,
  section.press #m-p3:checked ~ .row > .nav-arrow img.m-p3,
  section.press #m-p4:checked ~ .row > .nav-arrow img.m-p4,
  section.press #m-p5:checked ~ .row > .nav-arrow img.m-p5 {
    display: block;
  }
  section.press li.m {
    display: none;
  }
  section.press #m-p1:checked ~ .row * li.m-p1,
  section.press #m-p2:checked ~ .row * li.m-p2,
  section.press #m-p3:checked ~ .row * li.m-p3,
  section.press #m-p4:checked ~ .row * li.m-p4,
  section.press #m-p5:checked ~ .row * li.m-p5 {
    display: block;
  }
  section.press #m-p1:checked ~ .quotes .pull-quote a.p1,
  section.press #m-p2:checked ~ .quotes .pull-quote a.p2,
  section.press #m-p3:checked ~ .quotes .pull-quote a.p3,
  section.press #m-p4:checked ~ .quotes .pull-quote a.p4,
  section.press #m-p5:checked ~ .quotes .pull-quote a.p5 {
    display: block;
  }
  section.press #m-p1:checked ~ .quotes .pull-quote a,
  section.press #m-p2:checked ~ .quotes .pull-quote a,
  section.press #m-p3:checked ~ .quotes .pull-quote a,
  section.press #m-p4:checked ~ .quotes .pull-quote a,
  section.press #m-p5:checked ~ .quotes .pull-quote a {
    display: none;
  }
}

section.three-purrbles {
  height: 500px;
  background-image: url("/images/three-purrbles.jpg");
  z-index: 10;
  background-repeat: no-repeat;
  background-size: cover;
  background-position-x: 50%;
}

@media (max-width: 768px) {
  section.three-purrbles {
    background-image: url("/images/three-purrbles-m.jpg");
    background-position-y: 50%;
  }
}

section.meet-purrble {
  background-color: #fff;
  position: relative;
  min-height: 480px;
  z-index: -10;
}

section.meet-purrble div.container {
  margin-left: 0;
  padding-left: 0;
}

section.meet-purrble div.content {
  z-index: 1;
}

section.meet-purrble div.content svg {
  fill: #007d98;
  padding-left: 8px;
  width: 220px;
}

section.meet-purrble div.purrble-video {
  z-index: -10;
  width: 135%;
  margin-left: -50px;
  clip-path: inset(0 0 0 35px);
  margin-top: -6vw;
  margin-bottom: -2vw;
}

@media (max-width: 768px) {
  section.meet-purrble div.purrble-video {
    margin-left: -20vw;
    margin-top: -15vw;
  }
}

section.calm {
  background-color: #80cfd5;
  padding-top: 189px;
  padding-bottom: 189px;
}

section.calm div.calm-container {
  margin: 0 auto;
  max-width: 650px;
}

section.calm .calm-message {
  opacity: 0;
  text-align: center;
}

section.calm .fade-in {
  animation: fade-in 5s linear;
  animation-fill-mode: forwards;
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

section.product-photos {
  background-color: #FBF6EA;
}

section.bring-purrble-home {
  background-color: #007d98;
  padding: 0;
  height: 816px;
  position: relative;
}

section.bring-purrble-home div.wistia_embed {
  height: 820px;
}

section.bring-purrble-home div.video-title {
  margin: 0;
  height: 100px;
  position: absolute;
  left: 0;
  right: 0;
  top: 331px;
  z-index: 10;
}

section.bring-purrble-home div.video-title h1 {
  padding-bottom: 24px;
  text-shadow: 2.5px 2.5px #675340;
  line-height: 56px;
}

#drag-scroll {
  cursor: grab;
}

section.testimonials {
  background-color: #FBF6EA;
  padding-top: 119px;
  padding-bottom: 200px;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

section.testimonials ::-webkit-scrollbar {
  display: none;
}

section.testimonials .container {
  padding-right: 0;
}

section.testimonials .social-header {
  display: block;
}

section.testimonials .social-header .social-title h1, section.testimonials .social-header .social-title img, section.testimonials .social-header .social-title span {
  display: inline-block;
}

@media (max-width: 768px) {
  section.testimonials .social-header .social-title {
    text-align: center;
  }
}

section.testimonials .social-header .social-title .social-tag {
  display: flex;
  align-items: flex-end;
  padding-top: 30px;
  padding-right: 384px;
}

@media (max-width: 1920px) {
  section.testimonials .social-header .social-title .social-tag {
    padding-right: 117px;
  }
}

@media (max-width: 1407px) {
  section.testimonials .social-header .social-title .social-tag {
    padding-right: 57px;
  }
}

@media (max-width: 1023px) {
  section.testimonials .social-header .social-title .social-tag {
    padding-right: 57px;
  }
}

@media (max-width: 768px) {
  section.testimonials .social-header .social-title .social-tag {
    padding-right: 0;
  }
}

@media (min-width: 820px) {
  section.testimonials .social-header .social-title .social-tag {
    float: right;
  }
}

@media (max-width: 819.98px) {
  section.testimonials .social-header .social-title .social-tag {
    padding-top: 0;
    padding-bottom: 36px;
    margin: auto;
    width: 50%;
    text-align: center;
    display: block;
    float: none;
  }
}

@media (max-width: 1023px) {
  section.testimonials .social-header .social-title .social-tag {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

section.testimonials .social-header .social-title img {
  height: 28px;
}

section.testimonials .social-header .social-title span {
  color: #80cfd5;
  padding-left: 10px;
}

section.testimonials .testimonial-row {
  width: 100%;
  overflow-x: scroll;
  overflow-y: hidden;
  cursor: grab;
}

section.testimonials .testimonial-row-inner {
  white-space: nowrap;
}

section.testimonials .testimonial-image {
  background-color: #D2C6B0;
  width: 305px;
  height: 408px;
  border-radius: 30px;
  display: inline-block;
  margin-left: 7.5px;
  margin-right: 7.5px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position-x: 50%;
  background-position-y: 50%;
}

section.testimonials .testimonial-image.s1 {
  background-image: url("/images/social/social-01.jpg");
}

section.testimonials .testimonial-image.s2 {
  background-image: url("/images/social/social-02.jpg");
}

section.testimonials .testimonial-image.s3 {
  background-image: url("/images/social/social-03.jpg");
}

section.testimonials .testimonial-image.s4 {
  background-image: url("/images/social/social-04.jpg");
}

section.testimonials .testimonial-image.s5 {
  background-image: url("/images/social/social-05.jpg");
}

section.testimonials .testimonial-image.s6 {
  background-image: url("/images/social/social-06.jpg");
}

section.testimonials .testimonial-image:nth-last-child() {
  margin-right: 20px;
}

section.buy-purrble {
  background-color: #fff;
  padding-top: 186px;
}

@media (max-width: 768px) {
  section.buy-purrble {
    padding-top: 150px;
  }
}

@media (max-width: 1407px) {
  section.buy-purrble .container div.product-images {
    margin-left: -20px;
    margin-right: 20px;
  }
}

@media (max-width: 1023px) {
  section.buy-purrble .container {
    padding-right: 0;
    padding-left: 0;
  }
  section.buy-purrble .container div.product-images {
    padding-left: 5px;
    padding-right: 5px;
    margin-left: 0;
    margin-right: 0;
  }
}

@media (max-width: 768px) {
  section.buy-purrble .container {
    padding-left: 0px;
    padding-right: 0px;
  }
  section.buy-purrble .container div.product-images {
    margin-left: 0;
    margin-right: 0;
  }
}

section.buy-purrble .product-images {
  position: relative;
}

section.buy-purrble .product-images .nav-arrow {
  width: 20px;
  position: absolute;
  top: 50%;
}

section.buy-purrble .product-images .nav-arrow.left {
  left: 15px;
}

section.buy-purrble .product-images .nav-arrow.right {
  right: 15px;
}

section.buy-purrble input.radio-button {
  display: none;
}

section.buy-purrble .main-image {
  width: 100%;
  height: 100%;
  max-width: 400px;
  height: 400px;
  margin-left: auto;
  margin-right: auto;
}

section.buy-purrble .main-image img {
  max-width: 100%;
  max-height: 100%;
}

section.buy-purrble .main-image img.purrble-product {
  display: none;
}

section.buy-purrble .thumbnails {
  display: inline-block;
  margin-left: -50px;
  margin-right: -50px;
}

@media (max-width: 1023px) {
  section.buy-purrble .thumbnails {
    margin-left: 0;
    margin-right: 0;
  }
}

section.buy-purrble .thumbnails ul {
  display: block;
  list-style-type: none;
  float: left;
  max-width: 100%;
  padding-inline-start: 0;
}

section.buy-purrble .thumbnails ul li {
  display: list-item;
  float: left;
  padding-left: 9px;
  padding-right: 9px;
  cursor: pointer;
  width: 74px;
  height: 74px;
}

section.buy-purrble .thumbnails ul li img.thumbnail {
  display: block;
  max-width: 100%;
}

section.buy-purrble .thumbnails ul li:nth-child(1) {
  padding-left: 0;
}

section.buy-purrble .thumbnails ul li:last-child() {
  padding-right: 0;
}

section.buy-purrble #p1:checked ~ .main-image img.p1,
section.buy-purrble #p2:checked ~ .main-image img.p2,
section.buy-purrble #p3:checked ~ .main-image img.p3,
section.buy-purrble #p4:checked ~ .main-image img.p4,
section.buy-purrble #p5:checked ~ .main-image img.p5,
section.buy-purrble #p6:checked ~ .main-image img.p6 {
  display: block;
}

section.buy-purrble #p1:hover ~ .main-image img,
section.buy-purrble #p2:hover ~ .main-image img,
section.buy-purrble #p3:hover ~ .main-image img,
section.buy-purrble #p4:hover ~ .main-image img,
section.buy-purrble #p5:hover ~ .main-image img,
section.buy-purrble #p6:hover ~ .main-image img {
  display: none !important;
}

section.buy-purrble #p1:hover ~ .main-image img.p1,
section.buy-purrble #p2:hover ~ .main-image img.p2,
section.buy-purrble #p3:hover ~ .main-image img.p3,
section.buy-purrble #p4:hover ~ .main-image img.p4,
section.buy-purrble #p5:hover ~ .main-image img.p5,
section.buy-purrble #p6:hover ~ .main-image img.p6 {
  display: block !important;
}

section.buy-purrble #m-p1:checked ~ .main-image img.m-p1,
section.buy-purrble #m-p2:checked ~ .main-image img.m-p2,
section.buy-purrble #m-p3:checked ~ .main-image img.m-p3,
section.buy-purrble #m-p4:checked ~ .main-image img.m-p4,
section.buy-purrble #m-p5:checked ~ .main-image img.m-p5,
section.buy-purrble #m-p6:checked ~ .main-image img.m-p6 {
  display: block;
}

section.buy-purrble .main-image label {
  cursor: pointer;
}

section.buy-purrble .nav-arrow img {
  display: none;
  cursor: pointer;
}

section.buy-purrble #m-p1:checked ~ .nav-arrow img.m-p1,
section.buy-purrble #m-p2:checked ~ .nav-arrow img.m-p2,
section.buy-purrble #m-p3:checked ~ .nav-arrow img.m-p3,
section.buy-purrble #m-p4:checked ~ .nav-arrow img.m-p4,
section.buy-purrble #m-p5:checked ~ .nav-arrow img.m-p5,
section.buy-purrble #m-p6:checked ~ .nav-arrow img.m-p6 {
  display: block;
}

section.buy-purrble .product-title {
  display: block;
}

section.buy-purrble .product-title h1, section.buy-purrble .product-title p {
  display: inline-block;
}

@media (max-width: 768px) {
  section.buy-purrble .product-title {
    padding-top: 35px;
  }
}

section.buy-purrble .product-reviews {
  display: block;
}

@media (min-width: 1023px) {
  section.buy-purrble .product-reviews {
    white-space: nowrap;
  }
}

@media (max-width: 768px) {
  section.buy-purrble .product-reviews {
    padding-bottom: 22px;
  }
}

section.buy-purrble .product-reviews img {
  width: 128px;
}

section.buy-purrble .product-reviews img, section.buy-purrble .product-reviews p {
  display: inline-block;
}

section.buy-purrble .product-reviews p {
  font-size: 20px;
  vertical-align: text-bottom;
  padding-left: 6px;
  padding-right: 6px;
}

section.buy-purrble .product-description {
  padding-top: 33px;
}

section.buy-purrble .shipping-date-notice {
  padding-top: 33px;
}

section.buy-purrble .shipping-date-notice p {
  font-style: italic;
}

section.buy-purrble .shipping-date-notice ul {
  list-style-type: none;
  font-size: 28px;
}

section.buy-purrble .shipping-date-notice ul li {
  padding-top: 1.4rem;
  color: #007D98;
  line-height: 32px;
}

section.buy-purrble .shipping-date-notice ul li li {
  padding-top: 0.5rem;
}

section.buy-purrble .shipping-date-notice ul.no-indent {
  margin: 0;
  padding: 0;
}

section.buy-purrble .product-cta {
  padding-top: 33px;
}

@media (max-width: 768px) {
  section.buy-purrble .product-cta {
    padding-top: 15px;
  }
}

@media (max-width: 576px) {
  section.buy-purrble .product-cta {
    padding-top: 0px;
    margin-top: -15px;
  }
}

@media (max-width: 576px) {
  section.buy-purrble .product-cta button {
    padding-left: 40px;
    padding-right: 40px;
  }
}

section.features {
  background-color: #FBF6EA;
  padding-top: 133px;
  padding-bottom: 133px;
}

@media (max-width: 768px) {
  section.features {
    padding-top: 57px;
    padding-bottom: 57px;
  }
}

section.features .row {
  padding-bottom: 60px;
}

@media (max-width: 768px) {
  section.features .row {
    padding-bottom: 0;
  }
}

section.features .row:last-child {
  padding-bottom: 0;
}

@media (max-width: 768px) {
  section.features div.feature {
    padding-bottom: 25px;
  }
  section.features div.last-feature {
    padding-bottom: 0;
  }
}

section.features div.feature {
  display: inherit;
}

section.features div.feature div.feature-image {
  display: block;
  width: 120px;
  margin-left: 0;
  margin-right: 0;
  margin-top: auto;
  margin-bottom: auto;
  padding-right: 10px;
}

section.features div.feature div.feature-image img {
  margin-top: -30px;
  margin-bottom: -30px;
}

@media (max-width: 768px) {
  section.features div.feature div.feature-image img {
    margin-top: -24px;
    margin-bottom: -24px;
  }
}

section.features div.feature div.feature-description {
  display: block;
  width: 100%;
  font-weight: 700;
  font-size: 28px;
  line-height: 32px;
}

@media (max-width: 768px) {
  section.features div.feature div.feature-description {
    font-size: 24px;
    line-height: 32px;
  }
}

section.quotes {
  background-color: #fff;
  padding-top: 87px;
  padding-bottom: 60px;
  scrollbar-width: none;
}

@media (max-width: 768px) {
  section.quotes {
    padding-top: 52px;
  }
}

section.quotes ::-webkit-scrollbar {
  display: none;
}

section.quotes .quote-row {
  width: fit-content;
  max-width: 100%;
  overflow-x: scroll;
  margin-left: auto;
  margin-right: auto;
}

section.quotes .quote-row-inner {
  white-space: nowrap;
  margin-top: 0;
}

section.quotes .quote-bubble {
  background-color: #80cfd5;
  white-space: normal;
  width: 386px;
  display: inline-block;
  margin: 0 7.5px 0 7.5px;
  padding: 50px 42px 50px 42px;
  border-radius: 20px;
  vertical-align: top;
}

section.quotes .quote-bubble:nth-last-child() {
  margin-right: 0px;
}

section.backed-by-research {
  background-color: #80cfd5;
  padding-top: 60px;
  padding-bottom: 224px;
}

@media (max-width: 768px) {
  section.backed-by-research {
    padding-bottom: 124px;
  }
}

section.backed-by-research p.lead-in {
  padding-left: 30px;
}

section.backed-by-research p.lead-in.findings {
  padding-top: 10px;
}

section.backed-by-research .findings {
  padding-top: 12px;
  display: table;
  vertical-align: middle;
}

section.backed-by-research .findings span {
  display: table-cell;
  vertical-align: middle;
}

section.backed-by-research .findings h1.research-finding {
  text-shadow: 2.5px 2.5px #6DC3CC;
  margin-bottom: 0;
  padding-left: 30px;
  padding-right: 30px;
}

section.about-video {
  background: #80cfd5;
  padding: 0;
  height: 640px;
  position: relative;
}

@media (max-width: 576px) {
  section.about-video {
    top: 64px;
  }
}

section.about-video div.wistia_embed {
  height: 642px;
}

section.about-video div.video-title {
  margin: 0;
  height: 100px;
  position: absolute;
  left: 0;
  right: 0;
  top: 110px;
  z-index: 10;
}

@media (min-width: 1408px) {
  section.about-video div.video-title {
    top: 120px;
  }
}

@media (max-width: 768px) {
  section.about-video div.video-title {
    top: 55px;
  }
}

section.about-video div.video-title h1 {
  color: white;
  padding-bottom: 24px;
  font-size: 40px;
  font-weight: bold !important;
}

@media (min-width: 1408px) {
  section.about-video div.video-title h1 {
    padding-bottom: 24px;
  }
}

section.about-video div.video-title div.title-center {
  margin: 0 auto;
  width: 50%;
}

section.about-video div.video-title div.title-center p {
  font-size: 45px;
  line-height: 45px;
  color: white;
  font-weight: normal;
  margin: 30px 0 0 0;
  padding: 0 0 0 0;
  text-align: center;
  font-family: "brandon-grotesque";
  text-shadow: 2.5px 2.5px #675340;
}

section.about-video .w-video-wrapper video {
  object-position: center 25% !important;
}

section.collab {
  background-color: #007d98;
  padding-top: 78px;
  padding-bottom: 0px;
  color: white;
}

section.collab p {
  color: white;
  text-align: center;
}

section.collab div.logos {
  padding-top: 20px;
  margin: 20px auto;
  width: 100%;
  text-align: center;
}

section.collab div.logos div.logo {
  text-align: center;
  display: inline-block;
  height: 60px;
  margin: 20px 20px 0 20px;
}

section.collab div.logos div.logo img.partner-img {
  display: block;
}

@media (max-width: 768px) {
  section.collab div.logos div.logo img.partner-img {
    width: 250px;
  }
}

@media (min-width: 576px) {
  section.collab div.logos div.logo img.partner-img {
    height: 60px;
  }
}

section.collab div.purrble-peeking {
  padding-top: 65px;
}

section.collab div.purrble-peeking img.purrble-peeking {
  display: block;
  max-height: 150px;
  margin-left: auto;
  margin-right: auto;
}

section.click-media-kit {
  background-color: #80cfd5;
  padding-top: 120px;
  padding-bottom: 150px;
}

section.click-media-kit div.media-kit-info {
  margin: 0 auto;
}

section.click-media-kit div.media-kit-info a {
  color: #007d98;
}

section.click-media-kit div.media-kit-info p {
  text-align: center;
  color: #007d98;
  padding: 0 10px 0 10px;
}

section.click-media-kit div.media-kit-info p strong {
  font-weight: 400;
}

section.instruction-selection {
  background-color: #80cfd5;
  padding-top: 200px;
  padding-bottom: 200px;
}

section.instruction-selection a.instruction-button {
  display: inline-block;
  border: 6px solid #007d98;
  border-radius: 15px;
  padding: 120px 0 120px 0;
  margin: 15px;
  min-width: 200px;
  color: white;
  font-size: 40px;
  font-weight: bold;
}

section.instruction-selection a.instruction-button:hover {
  background-color: #69AAAF;
  text-decoration: none;
}
