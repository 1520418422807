@charset "utf-8";

// Framework
@import '_colors';
@import '_fonts';
@import '_framework';

// Header and Footer
@import '_header';
@import '_footer';

// Main site
section {
  padding-top: 4rem;
  padding-bottom: 4rem;

  // This div serves to block left/right clicks on the Wistia embeds
  div.click-block {
    min-height: 100%;
    min-width: 100%;

    position: absolute;
    top: 0px;
    left: 0px;
  }
}

section.video {
  background: $blue-light;
  padding: 0;
  height: 640px;
  position: relative;

  @media (max-width: $screen-sm-min) {
    top: 64px; // Spacing for two lines
  }

  div.wistia_embed {
    height: 642px;
  }

  div.video-title {
    margin: 0;
    height: 100px;
    position: absolute;
    left: 0;
    right: 0;
    top: 340px; // FIXME
    @media (min-width: $screen-xl-min) {
      top: 420px;
    }
    // @media ()
    // change top position for v-height
    //
    z-index: $z-index-video-title;

    h1 {
      color: $blue-deep;
      text-shadow: 2.5px 2.5px #B2D0D7;
      // text-shadow: 2.5px 2.5px $blue-light;
      padding-bottom: 24px; // FIXME rem
      @media (min-width: $screen-xl-min) {
        padding-bottom: 24px; // FIXME rem
      }
    }
  }

  // #wistia_simple_video_86 {
  .w-video-wrapper video {
    object-position: center 25% !important;
  }
}

section.press {
  background: $white-linen;
  padding-top: 80px;
  padding-bottom: 80px;

  .container {
    padding-left: 0;
    padding-right: 0;
  }

  input.radio-button {
    display: none;
  }

  .press-logos {
    position: relative;

    .nav-arrow {
      width: 20px;
      position: absolute;
      top: 22px;
      &.left {
        left: 15px;
      }
      &.right {
        right: 15px;
      }
    }
  }

  .logos {
    display: block;
    margin-left: auto;
    margin-right: auto;
    padding-left: 0;
    padding-right: 0;
  }



  // Selection
  ul {
    display: block;
    list-style-type: none;
    float: left;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0;
    max-width: 100%;
    padding-inline-start: 0;

    li {
      display: list-item;
      font-size: 1rem;
      float: left;
      border-bottom: 2px solid $press-bar-inactive;

      label {
        cursor: pointer;
      }

      svg {
        display: block;
        height: 80px; // min
        @media (min-width: $screen-xxl-min) {
          height: calc((100vw - 768px - 250px) / 15);
          min-height: 70px;
        }
        @media (max-width: $screen-xl-max) {
          height: calc((100vw - 234px - 250px) / 15);
          min-height: 70px;
        }
        @media (max-width: $screen-lg-max) {
          height: calc(70vw / 12);
          min-height: 67.9219px;
        }
        @media (max-width: $screen-md-max) {
          height: calc(80vw / 12);
          min-height: 58px;
        }
        @media (max-width: $screen-sm-max) {
          min-height: 72px;
        }
        max-height: 80px;
        fill: $press-bar-inactive;
        transition: fill 300ms ease-in-out;
        padding-left: 25px;
        padding-right: 25px;
        @media (max-width: $screen-lg-max) {
          padding-left: 1vw;
          padding-right: 1vw;
        }
        @media (max-width: $screen-md-max) {
          padding-left: 1vw;
          padding-right: 1vw;
        }
        padding-top: 8px;
        padding-bottom: 24px;
      }
    }
  }

  .pull-quote {
    display: block;
    margin-left: auto;
    margin-right: auto;

    a {
      display: none;
      text-decoration: none;
      padding-top: 60px;
    }
  }

  // > md
  @media (min-width: $screen-md-min) {
    #p1:checked ~ .row * li.p1, #p1:hover ~ .row * li.p1,
    #p2:checked ~ .row * li.p2, #p2:hover ~ .row * li.p2,
    #p3:checked ~ .row * li.p3, #p3:hover ~ .row * li.p3,
    #p4:checked ~ .row * li.p4, #p4:hover ~ .row * li.p4,
    #p5:checked ~ .row * li.p5, #p5:hover ~ .row * li.p5 {
      border-bottom: 8px solid $press-bar-inactive;
      svg {
        fill: $press-bar-active;
      }
    }

    #p1:checked ~ .quotes .pull-quote a.p1,
    #p2:checked ~ .quotes .pull-quote a.p2,
    #p3:checked ~ .quotes .pull-quote a.p3,
    #p4:checked ~ .quotes .pull-quote a.p4,
    #p5:checked ~ .quotes .pull-quote a.p5 {
      display: block;
    }

    #p1:checked ~ .quotes .pull-quote a,
    #p2:checked ~ .quotes .pull-quote a,
    #p3:checked ~ .quotes .pull-quote a,
    #p4:checked ~ .quotes .pull-quote a,
    #p5:checked ~ .quotes .pull-quote a {
      display: none;
    }

    // If hovering, hide everything and show only what's being hovered on
    #p1:hover ~ .quotes .pull-quote a,
    #p2:hover ~ .quotes .pull-quote a,
    #p3:hover ~ .quotes .pull-quote a,
    #p4:hover ~ .quotes .pull-quote a,
    #p5:hover ~ .quotes .pull-quote a {
      display: none !important;
    }
    #p1:hover ~ .quotes .pull-quote a.p1,
    #p2:hover ~ .quotes .pull-quote a.p2,
    #p3:hover ~ .quotes .pull-quote a.p3,
    #p4:hover ~ .quotes .pull-quote a.p4,
    #p5:hover ~ .quotes .pull-quote a.p5 {
      display: block !important;
    }
  }

  // xs and sm
  @media (max-width: $screen-sm-max) {
    .row * li {
      border-bottom: 8px solid $press-bar-inactive;
      svg {
        fill: $press-bar-active;
      }
    }

    .nav-arrow img {
      display: none;
      cursor: pointer;
    }
    #m-p1:checked ~ .row > .nav-arrow img.m-p1,
    #m-p2:checked ~ .row > .nav-arrow img.m-p2,
    #m-p3:checked ~ .row > .nav-arrow img.m-p3,
    #m-p4:checked ~ .row > .nav-arrow img.m-p4,
    #m-p5:checked ~ .row > .nav-arrow img.m-p5 {
      display: block;
    }


    li.m {
      display: none;
    }
    #m-p1:checked ~ .row * li.m-p1,
    #m-p2:checked ~ .row * li.m-p2,
    #m-p3:checked ~ .row * li.m-p3,
    #m-p4:checked ~ .row * li.m-p4,
    #m-p5:checked ~ .row * li.m-p5 {
      display: block;
    }

    #m-p1:checked ~ .quotes .pull-quote a.p1,
    #m-p2:checked ~ .quotes .pull-quote a.p2,
    #m-p3:checked ~ .quotes .pull-quote a.p3,
    #m-p4:checked ~ .quotes .pull-quote a.p4,
    #m-p5:checked ~ .quotes .pull-quote a.p5 {
      display: block;
    }

    #m-p1:checked ~ .quotes .pull-quote a,
    #m-p2:checked ~ .quotes .pull-quote a,
    #m-p3:checked ~ .quotes .pull-quote a,
    #m-p4:checked ~ .quotes .pull-quote a,
    #m-p5:checked ~ .quotes .pull-quote a {
      display: none;
    }
  }
}

section.three-purrbles {
  height: 500px;
  background-image: url("/images/three-purrbles.jpg");
  z-index: 10;
  @media (max-width: $screen-sm-max) {
    background-image: url("/images/three-purrbles-m.jpg");
    background-position-y: 50%;
  }
  background-repeat: no-repeat;
  background-size: cover;
  background-position-x: 50%;
}

section.meet-purrble {
  background-color: $white;
  position: relative;
  min-height: 480px;
  z-index: -10; // Push this behind the images above

  div.container {
    margin-left: 0;
    padding-left: 0;
  }
  div.content {
    z-index: 1;
    svg {
      fill: $blue-deep;
      padding-left: 8px;
      width: 220px;
    }
  }
  div.purrble-video {
    z-index: -10;
    width: 135%;
    margin-left: -50px;
    clip-path: inset(0 0 0 35px);
    margin-top: -6vw;
    margin-bottom: -2vw;
    @media (max-width: $screen-sm-max) {
      margin-left: -20vw;
      margin-top: -15vw;
    }
  }
}

section.calm {
  background-color: $blue-light;
  padding-top: 189px;
  padding-bottom: 189px;
  
  div.calm-container {
    margin: 0 auto;
    max-width: 650px;
  }

  .calm-message {
    opacity: 0;
    text-align: center;
  }

  .fade-in {
    animation: fade-in 5s linear;
    animation-fill-mode: forwards;
  }

}

@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

section.product-photos {
  background-color: $white-linen;
}

section.bring-purrble-home {
  background-color: $blue-deep;
  padding: 0;
  height: 816px;
  position: relative;

  div.wistia_embed {
    height: 820px;
  }

  div.video-title {
    margin: 0;
    height: 100px;
    position: absolute;
    left: 0;
    right: 0;
    top: 331px; // FIXME
    // @media ()
    // change top position for v-height
    //
    z-index: $z-index-video-title;

    h1 {
      padding-bottom: 24px; // FIXME rem
      // text-shadow: 2.5px 2.5px #4B4744; // Asphault
      // text-shadow: 2.5px 2.5px #868485;
      // text-shadow: 2.5px 2.5px #897A66;
      // text-shadow: 2.5px 2.5px #897A66;
      text-shadow: 2.5px 2.5px #675340; // Package
      line-height: 56px;
    }
  }
}

#drag-scroll {
  cursor: grab;
}

section.testimonials {
  background-color: $white-linen;
  padding-top: 119px;
  padding-bottom: 200px;
  
  ::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;

  .container {
    padding-right: 0;
  }

  .social-header {
    display: block;

    .social-title {
      h1, img, span {
        display: inline-block;
      }
      @media (max-width: $screen-sm-max) {
        text-align: center;
      }
      .social-tag {
        display: flex;
        align-items: flex-end;
        padding-top: 30px;
        padding-right: $xxl-padding-header;
        @media (max-width: $screen-xl-max) {
          padding-right: $xl-padding-header;
        }
        @media (max-width: $screen-lg-max) {
          padding-right: $lg-padding-header;
        }
        @media (max-width: $screen-md-max) {
          padding-right: $md-padding-header;
        }
        @media (max-width: $screen-sm-max) {
          padding-right: 0;
        }
  
        // Hardcode custom sizing
        @media (min-width: 820px) {
          float: right;
        }
        // Note: Bootstrap recommends this weird 0.2px difference
        @media (max-width: 819.98px) {
          padding-top: 0;
          //padding-right: 0;
          //padding-left: 117px;
          padding-bottom: 36px;
          margin: auto;
          width: 50%;
          text-align: center;
          display: block;
          float: none;
        }

        @media (max-width: $screen-md-max) {
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
      img {
        height: 28px;
      }
      span {
        color: $blue-light;
        padding-left: 10px;
      }
    }
  }

  .testimonial-row {
    width: 100%;
    overflow-x: scroll;
    overflow-y: hidden;
    cursor: grab;
  }

  .testimonial-row-inner {
    white-space: nowrap;
  }

  .testimonial-image {
    background-color: #D2C6B0;
    width: 305px;
    height: 408px;
    border-radius: 30px;
    display: inline-block;
    margin-left: 7.5px;
    margin-right: 7.5px;

    background-repeat: no-repeat;
    background-size: cover;
    background-position-x: 50%;
    background-position-y: 50%;

    &.s1 {
      background-image: url("/images/social/social-01.jpg");
    }

    &.s2 {
      background-image: url("/images/social/social-02.jpg");
    }

    &.s3 {
      background-image: url("/images/social/social-03.jpg");
    }

    &.s4 {
      background-image: url("/images/social/social-04.jpg");
    }

    &.s5 {
      background-image: url("/images/social/social-05.jpg");
    }

    &.s6 {
      background-image: url("/images/social/social-06.jpg");
    }
  }

  .testimonial-image:nth-last-child() {
    margin-right: 20px;
  }

}

// 
// Product
// 

section.buy-purrble {
  background-color: $white;
  padding-top: 186px;
  
  @media (max-width: $screen-sm-max) {
    padding-top: 150px; // Spacing for two lines
  }

  .container {
    @media (max-width: $screen-lg-max) {
      div.product-images {
        margin-left: -20px;
        margin-right: 20px;
      }
    }

    @media (max-width: $screen-md-max) {
      padding-right: 0;
      padding-left: 0;
      div.product-images {
        padding-left: 5px;
        padding-right: 5px;
        margin-left: 0;
        margin-right: 0;
      }
    }
    @media (max-width: $screen-sm-max) {
      padding-left: $sm-padding-body;
      padding-right: $sm-padding-body;
      div.product-images {
        margin-left: 0;
        margin-right: 0;
      }
    }

  }

  .product-images {
    position: relative;
    .nav-arrow {
      width: 20px;
      position: absolute;
      top: 50%;
      &.left {
        left: 15px;
      }
      &.right {
        right: 15px;
      }
    }
  }

  input.radio-button {
    display: none;
  }
  
  .main-image {
    width: 100%;
    height: 100%;
    max-width: 400px;
    height: 400px;
    margin-left: auto;
    margin-right: auto;

    img {
      max-width: 100%;
      max-height: 100%;
    
      &.purrble-product {
        display: none;
      }
    }
  }

  // Selection
  .thumbnails {
    display: inline-block;
    margin-left: -50px;
    margin-right: -50px;
    @media (max-width: $screen-md-max) {
      margin-left: 0;
      margin-right: 0;
    }

    ul {
      display: block;
      list-style-type: none;
      float: left;
      max-width: 100%;
      padding-inline-start: 0;
  
      li {
        display: list-item;
        float: left;
        padding-left: 9px;
        padding-right: 9px;
        cursor: pointer;
        width: 74px;
        height: 74px;
  
        img.thumbnail {
          display: block;
          max-width: 100%;
        }
      }
      li:nth-child(1) {
        padding-left: 0;
      }
      li:last-child() {
        padding-right: 0;
      }
    }
  }

  #p1:checked ~ .main-image img.p1,
  #p2:checked ~ .main-image img.p2, 
  #p3:checked ~ .main-image img.p3,
  #p4:checked ~ .main-image img.p4,
  #p5:checked ~ .main-image img.p5,
  #p6:checked ~ .main-image img.p6 {
    display: block;
  }

  // If hovering, hide everything and show only what's being hovered on
  #p1:hover ~ .main-image img,
  #p2:hover ~ .main-image img,
  #p3:hover ~ .main-image img,
  #p4:hover ~ .main-image img,
  #p5:hover ~ .main-image img,
  #p6:hover ~ .main-image img {
    display: none !important;
  }
  #p1:hover ~ .main-image img.p1,
  #p2:hover ~ .main-image img.p2,
  #p3:hover ~ .main-image img.p3,
  #p4:hover ~ .main-image img.p4,
  #p5:hover ~ .main-image img.p5,
  #p6:hover ~ .main-image img.p6 {
    display: block !important;
  }

  //
  // Mobile
  //
  #m-p1:checked ~ .main-image img.m-p1,
  #m-p2:checked ~ .main-image img.m-p2, 
  #m-p3:checked ~ .main-image img.m-p3,
  #m-p4:checked ~ .main-image img.m-p4,
  #m-p5:checked ~ .main-image img.m-p5,
  #m-p6:checked ~ .main-image img.m-p6 {
    display: block;
  }

  .main-image label {
    cursor: pointer;
  }

  .nav-arrow img {
    display: none;
    cursor: pointer;
  }
  #m-p1:checked ~ .nav-arrow img.m-p1,
  #m-p2:checked ~ .nav-arrow img.m-p2,
  #m-p3:checked ~ .nav-arrow img.m-p3,
  #m-p4:checked ~ .nav-arrow img.m-p4,
  #m-p5:checked ~ .nav-arrow img.m-p5,
  #m-p6:checked ~ .nav-arrow img.m-p6 {
    display: block;
  }

  .product-title {
    display: block;
    // padding-top: 50px;
    h1, p {
      display: inline-block;
    }

    @media (max-width: $screen-sm-max) {
      padding-top: 35px;
    }
  }
  .product-reviews {
    display: block;
    @media (min-width: $screen-md-max) {
      white-space: nowrap;
    }
    @media (max-width: $screen-sm-max) {
      padding-bottom: 22px;
    }
    img {
      width: 128px;
    }
    img, p {
      display: inline-block;
    }
    p {
      font-size: 20px;
      vertical-align: text-bottom;
      padding-left: 6px;
      padding-right: 6px;
    }
  }
  .product-description {
    padding-top: 33px;
  }
  .shipping-date-notice {
    padding-top: 33px;

    p {
      font-style: italic;
    }

    ul {
      list-style-type: none;
      font-size: 28px;
      li {
        padding-top: 1.4rem;
        color: $text-blue;
        line-height: 32px;
        li {
          padding-top: 0.5rem;
        }
      }
    }
    ul.no-indent {
      margin: 0;
      padding: 0;
    }

  }
  .product-cta {
    padding-top: 33px;
    @media (max-width: $screen-sm-max) {
      padding-top: 15px;
    }
    @media (max-width: $screen-sm-min) {
      padding-top: 0px;
      margin-top: -15px;
    }

    button {
      @media (max-width: $screen-sm-min) {
        padding-left: 40px;
        padding-right: 40px;
      }
    }
  }

}

section.features {
  background-color: $white-linen;
  padding-top: 133px;
  padding-bottom: 133px;
  @media (max-width: $screen-sm-max) {
    padding-top: 57px;
    padding-bottom: 57px;
  }

  .row {
    padding-bottom: 60px;
    @media (max-width: $screen-sm-max) {
      padding-bottom: 0;
    }
  }
  .row:last-child {
    padding-bottom: 0;
  }

  @media (max-width: $screen-sm-max) {
    div.feature {
      padding-bottom: 25px;
    }
    div.last-feature {
      padding-bottom: 0;
    }
  }

  div.feature {
    display: inherit;

    div.feature-image { 
      display: block;
      width: 120px;
      // height: 82px;
      margin-left: 0;
      margin-right: 0;
      margin-top: auto;
      margin-bottom: auto;
      padding-right: 10px;
      img {
        // FIXME
        // Visual hack
        // Use -30px to make 100% consistent spacing between text
        // Use > -9px to make 100% consistent spacing between images (based on padding in images)
        margin-top: -30px;
        margin-bottom: -30px;
        @media (max-width: $screen-sm-max) {
          margin-top: -24px;
          margin-bottom: -24px;
        }
      }
    }
    div.feature-description {
      display: block;
      width: 100%;
      font-weight: 700;
      font-size: 28px;
      line-height: 32px;
      @media (max-width: $screen-sm-max) {
        font-size: 24px;
        line-height: 32px;
      }
    }
  }

}

section.quotes {
  background-color: $white;
  padding-top: 87px;
  padding-bottom: 60px;
  @media (max-width: $screen-sm-max) {
    padding-top: 52px;
  }

  ::-webkit-scrollbar {
    display: none;
  }
  scrollbar-width: none;

  .quote-row {
    width: fit-content;
    max-width: 100%;
    overflow-x: scroll;
    margin-left: auto;
    margin-right: auto;
  }

  .quote-row-inner {
    white-space: nowrap;
    margin-top: 0;
  }

  .quote-bubble {
    background-color: $blue-light;
    white-space: normal;
    width: 386px;
    display: inline-block;
    margin: 0 7.5px 0 7.5px;
    padding: 50px 42px 50px 42px;
    border-radius: 20px;
    vertical-align: top;
  }

  .quote-bubble:nth-last-child() {
    margin-right: 0px;
  }

}

section.backed-by-research {
  background-color: $blue-light;
  padding-top: 60px;
  padding-bottom: 224px;
  @media (max-width: $screen-sm-max) {   
    padding-bottom: 124px;
  }
  
  p.lead-in {
    padding-left: 30px;
  }

  p.lead-in.findings {
    padding-top: 10px;
  }

  .findings {
    padding-top: 12px;
    display: table;
    vertical-align: middle;

    span {
      display: table-cell;
      vertical-align: middle;
    }

    h1.research-finding {
      text-shadow: 2.5px 2.5px #6DC3CC;
      margin-bottom: 0;
      padding-left: 30px;
      padding-right: 30px;
    }

  }
}

section.about-video {
  background: $blue-light;
  padding: 0;
  height: 640px;
  position: relative;

  @media (max-width: $screen-sm-min) {
    top: 64px; // Spacing for two lines
  }

  div.wistia_embed {
    height: 642px;
  }

  div.video-title {
    margin: 0;
    height: 100px;
    position: absolute;
    left: 0;
    right: 0;
    top: 110px; // FIXME
    @media (min-width: $screen-xl-min) {
      top: 120px;
    }
    @media (max-width: $screen-sm-max) {   
      top: 55px;
    }
    // @media ()
    // change top position for v-height
    //
    z-index: $z-index-video-title;

    h1 {
      color: white;
      //text-shadow: 2.5px 2.5px #B2D0D7;
      // text-shadow: 2.5px 2.5px $blue-light;
      padding-bottom: 24px; // FIXME rem
      @media (min-width: $screen-xl-min) {
        padding-bottom: 24px; // FIXME rem
      }

      font-size: 40px;
      font-weight: bold !important;

      
    }

    div.title-center {
      margin: 0 auto;
      width: 50%;
      p {
        font-size: 45px;
        line-height: 45px;
        color: white;
        font-weight: normal;
        //max-width: 400px;
        margin: 30px 0 0 0;
        padding: 0 0 0 0;
        text-align: center;
        font-family: "brandon-grotesque";
        text-shadow: 2.5px 2.5px #675340;
      }
    }
  }

  // #wistia_simple_video_86 {
  .w-video-wrapper video {
    object-position: center 25% !important;
  }
}

section.collab {
  background-color: $blue-deep;
  // background-color: $blue-light;
  // background-color: $white;
  padding-top: 78px;
  padding-bottom: 0px;
  color: white;

  p {
    color: white;
    // color: $blue-deep;
    text-align: center;
  }

  div.logos {
    padding-top: 20px;
    margin: 20px auto;
    width: 100%;
    text-align: center;

    div.logo {
      text-align: center;
      display: inline-block;
      height: 60px;
      margin: 20px 20px 0 20px;

      img.partner-img {
        display: block;

        @media (max-width: $screen-sm-max) {
          width: 250px;
        }

        @media (min-width: $screen-sm-min) {
          height: 60px;
        }
      }
    }
  }

  div.purrble-peeking {
    padding-top: 65px;

    img.purrble-peeking {
      display: block;
      max-height: 150px;
      // margin-bottom: -4px;
      margin-left: auto;
      margin-right: auto;
  
      // // This addresses the 1 pixel gap on iPhone
      // @media (max-width: $screen-sm-max) {   
      //   margin-bottom: -5px;
      // }
    }
  }
}

section.click-media-kit {
  background-color: $blue-light;
  padding-top: 120px;
  padding-bottom: 150px;
  div.media-kit-info {
    margin: 0 auto;
    a {
      color: $blue-deep;
    }
    p {
      text-align: center;
      color: $blue-deep;
      padding: 0 10px 0 10px;
      strong {
        font-weight: 400;
      }
    }
  }
  
}

section.instruction-selection {
  background-color: $blue-light;
  padding-top: 200px;
  padding-bottom: 200px;

  a.instruction-button {
    display: inline-block;
    border: 6px solid $blue-deep;
    border-radius: 15px;
    padding: 120px 0 120px 0;
    margin: 15px;
    min-width: 200px;
    // min-height: 408px;
    color: white;
    font-size: 40px;
    font-weight: bold;
  }
  a.instruction-button:hover {
    background-color: #69AAAF;
    text-decoration: none;
  }
}