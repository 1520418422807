footer {
  padding-bottom: 2rem;
  background-color: $blue-deep;
  position: relative;
  
  div.calm-wave {
    background-image: url(/images/footer-wave.svg);
    background-repeat: repeat-x;
    user-select: none;
    white-space: nowrap;
    position: absolute;
    top: -32px;
    width: 100%;
    height: 50px;
    
    animation: wave 120s linear infinite;

    @media (prefers-reduced-motion) { 
      animation: none;
    }

    // max-width: 100%;
    // overflow-x: hidden;
    transform: translate3d(0, 0, 0);
  }

  div.container {
    padding-top: 5rem;
  }

  .footer-company {
    float: left;
    @media (max-width: $screen-sm-max) {
      margin-left: auto;
      margin-right: auto;
      width: 100%;
      ul {
        margin-left: auto;
        margin-right: auto;
        width: 100%;
      }
    }

    ul {
      padding-inline-start: 0;
      li {
        svg {
          width: 150px;
        }
      }
    }
  }

  .footer-links {
    margin-left: auto;
    margin-right: 0;
    padding-left: 0;
    padding-right: 0;
    @media (max-width: $screen-sm-max) {
      margin-left: auto;
      margin-right: auto;
      padding-top: 24px;
      ul {
        padding-inline-start: 0;
        padding-left: 15px;
        padding-right: 15px;
      }
    }
  }

  img.logo {
    max-width: 150px;
  }

  ul {
    display: block;
    list-style-type: none;
    float: left;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0;

    li {
      display: list-item;
      font-size: 1rem;

      strong {
        color: white;
        font-weight: 400;
      }

      a {
        color: $text-off-white;
        
        &:hover {
          color: white;
          text-decoration: underline;
          transition: color linear 80ms;
        }
      }

    }
  }

  .made-with-love {
    margin-top: 4rem;
    color: $text-off-white;
    font-size: 16px; // FIXME rem

    img {
      width: 4rem;
      height: auto;
      margin-bottom: -0.25rem;
      margin-left: -0.25rem;
    }

    // a.sproutel-logo:hover {
    //   img {
    //     content: url("../images/sproutel-logo-white-2x.png");
    //   }
    // }
  }

  .copyright {
    width: 90%;
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;

    p {
      color: $text-off-white;
      font-size: 12.8px; // FIXME rem
      line-height: normal;
      vertical-align: middle;
      
      span.symbol {
        font-size: 8px;
        vertical-align: middle;
      }
    }
  }
}

@keyframes wave {
  0% {
    background-position-x: 0px;
  }
  100% {
    background-position-x: 6400px;
  }
}