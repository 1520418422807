header {
  position: fixed;
  left: 0;
  right: 0;
  z-index: $z-index-header;
  // Media query for header size

  // Holiday Shipping Notice, 2021-12
  div.holiday-deadline {
    background-color: $blue-deep;
    width: 100%;
    p.message {
      color: $white;
      font-size: 20px;
      font-family: "brandon-grotesque";
      font-weight: 700;
      margin: auto;
      padding-top: 4px;
      padding-bottom: 4px;
      // height: 32px;
      // overflow: hidden;
    }
    p.message.is-hidden-mobile {
      display: inline-block;
      @media (max-width: $screen-sm-min) {
        display: none;
      }
    }
    p.message.is-visible-mobile {
      display: none;
      @media (max-width: $screen-sm-min) {
        display: inline-block;
      }
    }
  }

  // BFCM, 2022-11
  div.discount {
    background-color: $text-yellow;
    width: 100%;
    text-align: center;
    padding-top: 10px;
    padding-bottom: 10px;
    a.message {
      // color: $white;
      color: $text-blue;
      font-size: 20px;
      font-family: "brandon-grotesque";
      font-weight: 700;
      margin: auto;
      padding-top: 4px;
      padding-bottom: 4px;
      text-decoration: none;
      // height: 32px;
      // overflow: hidden;
    }
    a.message.is-hidden-mobile {
      display: inline-block;
      @media (max-width: $screen-sm-min) {
        display: none;
      }
    }
    a.message.is-visible-mobile {
      display: none;
      @media (max-width: $screen-sm-min) {
        display: inline-block;
      }
    }
    br.is-visible-mobile {
      display: none;
      @media (max-width: $screen-sm-min) {
        display: inline-block;
      }
    }
  }

  nav {
    background-color: rgba(255, 255, 255, 1.0);
    transition: all 300ms ease-in-out;
    a {
      color: $blue-deep;
    }
    svg {
      fill: $blue-deep;
    }
  }

  nav.is-transparent {
    background-color: rgba(255, 255, 255, 0);
    a {
      color: $text-white;
    }
    svg {
      fill: $text-white;
    }
  }

  nav.no-transparency {
    background-color: rgba(255, 255, 255, 1.0);
    a {
      color: $blue-deep;
    }
    svg {
      fill: $blue-deep;
    }
  }

  .container {
    padding-top: 40px;
    // padding-left: 120px;
    // padding-right: 120px;
    padding-bottom: 10px;
  }

  .row { 
    margin: 0;
  }

  .header-logo .logo {
    display: block;
    width: 180px;
    // height: 64px;
  }

  a.mobile-cart {
    display: none;
  }

  .menu {
    margin-left: auto;
    margin-right: 0;
  }

  .menu div.main-menu {
    ul {
      display: block;
      list-style-type: none;
      float: left;
      font-size: 14px;
      line-height: 24px;
  
      li {
        display: list-item;
        float: left;
        font-size: 1rem;

        a {
          font-family: "brandon-grotesque";
          text-transform: uppercase;
          text-underline-offset: 4px; //FIXME rem
          &:hover {
            color: $text-yellow;
            svg { 
              fill: $text-yellow;
            }
          }
        }
      }

      li.text-item {
        padding: 14px 15px 14px 15px
      }
      li.text-item:nth-child(1) {
        padding-left: 0;
      }

      li.image-item {
        padding: 0 0 0 15px;
        span {
          display: none;
          visibility: hidden;
        }
      }
    }
  }

  .dim-the-lights {
    display: none;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: -1;
    position: fixed;
    top: 0;
  }
}

// Collapse the menu on smaller screens
// @media (max-width: 779px) {
@media (max-width: $screen-sm-max) {
  header {
    .container {
      padding-top: 12px;
      padding-left: 0;
      padding-right: 0;
      text-align: center;
    }

    a.mobile-cart {
      display: block;
      position: absolute;
      right: 0;
      svg {
        position: relative;
        top: 6px;
        right: 15px;
      }
    }
  }

  .header-logo {
    margin: auto;

    .logo {
      width: 180px;
    }
  }

  .main-menu {
    display: none;
    width: 100%;
  }

  a.menu-open {
    position: absolute;
    left: 0;
  }

  img.main-menu-toggle {
    width: 50px;
    height: 55px;
    position: relative;
    top: 0px;
    left: 0px;
  }

  #main-menu:target nav, #main-menu[aria-expanded="true"] nav {
    background-color: $blue-light;
    svg {
      fill: $text-white;
    }
  }

  #main-menu:target .main-menu, #main-menu[aria-expanded="true"] .main-menu {
    display: block;
  }
  #main-menu:target .dim-the-lights, #main-menu[aria-expanded="true"] .dim-the-lights {
    display: block;
  }
  #main-menu:target .menu-close, #main-menu[aria-expanded="true"] .menu-close {
    z-index: 1001;
    display: none;
  }
  #main-menu:target .mobile-cart, #main-menu[aria-expanded="true"] .mobile-cart {
    display: none;
  }
  #main-menu:target .close-menu, #main-menu[aria-expanded="true"] .close-menu {
    z-index: 1001;
    display: block;
    width: 61px;
    height: 61px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 32px;
    margin-bottom: 165px;
  }
  #main-menu:target ul, #main-menu[aria-expanded="true"] ul {
    z-index: 1000;
    float: none;
    padding-inline-start: 0;

    li {
      float: none;
      padding: 32px 0 32px 0;
      
      a {
        color: $blue-deep;
        font-family: $family-sans-serif;
        font-size: 32px; // FIXME
        font-weight: 700;
        text-transform: none;

        svg {
          display: none;
        }

        span {
          display: block;
          visibility: visible;
        }
      }
    }
    li:nth-child(1) {
      padding-top: 80px;
    }
  }
  
  // Open the menu
  a.menu-open {
    display: block;
  }
  #main-menu:target *.menu-open, #main-menu[aria-expanded="true"] *.menu-open {
    display: none;
  }
  
  // Close the menu
  a.menu-close {
    display: none;
  }
  #main-menu:target a.menu-close, #main-menu[aria-expanded="true"] a.menu-close {
    display: block;
  }
  
  .menu {
    position: relative;
    width: 100%;
  }
}
